@import '../../assets/less/less-var.less';
@import '../../assets/less/less-func.less';
.cust-button {
  .button-container {
    // overflow: hidden;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    // border-radius: 8px;
    cursor: pointer;

    .button-text {
      min-width: 87px;
      background: #fff;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #334150;
      border: 1px solid #e1e8ee;
    }

    .button-text-drak {
      background: #3f9cff;
      color: #fff;
      border-color: #3f9cff;
    }

    .cust-button-loading {
      line-height: 16px;
      position: absolute;
      display: inline-block;
    }
  }

  .button-disabled-primary {
    background: #332e1c;
    cursor: not-allowed;
    opacity: 0.6;

    .button-text {
      &:hover {
        .ancho,
        .narrow {
          transform: none !important;
        }
      }
    }
  }
}
