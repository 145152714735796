@minWidth: 1200px;
@pcHeight: 66px;
@pcFooterHeight: 300px;
@mobileFooterHeight: 340px;
@mobileHeight: 80px;
@pcMinHeight: calc(100vh - @pcHeight - @pcFooterHeight);
@mobileMinHeight: calc(100vh - @mobileHeight - @mobileFooterHeight -20px);
@typeCenter: 1200px;

@keyframes inft-space-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}

.global-skeleton-loading {
  width: 100%;
  height: 40px;
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.2) 25%,
    rgba(129, 129, 129, 0.24) 37%,
    rgba(190, 190, 190, 0.2) 63%
  );
  background-size: 400% 100%;

  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: inft-space-skeleton-loading;
}
// 限制行数
.limit-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制显示两行 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word; /* 换行 */
}
.hover-up-animation {
  transform: translateY(0px);
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: translateY(-4px);
    transition: all 0.5s ease-in-out;
  }
}

.hover-scale-animation {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
  }
}

.hover-scale-shake-animation {
  transform: scale(1);
  transition: transform 0.5s ease-in-out; /* 控制离开时的缩小动画 */
  transform-origin: center 80%; /* 让抖动的中心点下移 */
}

.hover-scale-shake-animation:hover {
  transform: scale(1.14); /* 悬停时变大 */
  animation: shake 0.6s ease-in-out; /* 无限循环摇晃 */
}

.hover-scale-shake-animation:not(:hover) {
  animation: none; /* 移开时立即停止摇晃 */
  transform: scale(1); /* 平滑缩小回原始大小 */
  transition: all 0.5s ease-in-out;
}

@keyframes shake {
  0%,
  60% {
    transform: scale(1.14) rotate(-10deg);
  }
  60%,
  90% {
    transform: scale(1.14) rotate(5deg);
  }
  90%,
  100% {
    transform: scale(1.14) rotate(0deg);
  }
}
